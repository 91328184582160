<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What would be the best way to prove you had learned the material presented to you this term
        (I'm asking about making the final exam a different format, like writing a paper, making a
        video, allowing a verbal exam, etc)
        <i>Open ended.</i>
      </p>

      <s-textarea v-model="inputs.input1" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'OleMissSurveyQ15',
  components: {
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
<style scoped></style>
